#page-content {
  margin-top: 1rem;
  overflow: auto !important;

  .lms-table {
    border-radius: 1px;
    width: 100%;
    th.mat-header-cell {
      padding: 0 8px;
      text-align: center !important;
    }

    &.first-col-link {
      td:nth-child(1) {
        cursor: pointer;

        &:hover {
          text-decoration: underline;
          text-underline-offset: 4px;
        }
      }
    }

    //Header cell styles
    th {
      .mat-select {
        background-color: white !important;
      }
      p {
        margin: 4px 0;
      }
    }

    .mat-header-cell {
      justify-content: center;
      background-color: #2d5660 !important;
      color: white !important;
      font-size: 14px;
    }
    // Table cell styles
    td.mat-cell {
      padding: 0 8px;

      p,
      input,
      select {
        margin: 0;
      }

      input,
      select {
        // {
        min-height: 32px;
        padding: 4px !important;
        width: 100%;
        background-color: white;
        border: 1px solid lightgrey;

        // margin: 0;
        &:focus {
          outline: none !important;
          border: 1px solid #702082;
        }
      }
    }

    .mat-form-field-appearance-outline .mat-form-field-outline {
      color: black !important;
      opacity: 0.5;
    }

    ::ng-deep .mat-form-field-label {
      color: black !important;
    }

    @media screen and (max-width: 1440px) {
      th.mat-header-cell,
      td.mat-cell {
        padding: 0 4px;
      }
    }

    //Table cell with link class for drill down links
    td.link {
      cursor: pointer;

      &:hover {
        text-decoration: underline;
        text-underline-offset: 4px;
      }
    }

    button {
      &.mat-raised-button {
        color: black !important;
      }

      &.mat-button-disabled {
        color: gray;
      }
    }
  }

  .table-wrapper {
    overflow: auto;
  }
}

/* @media for screen size : 1440px */
@media screen and (max-width: 1440px) {
  #page-content {
    .lms-table {
      th.mat-header-cell {
        padding: 0 4px;
      }

      mat-sort-header {
        justify-content: center !important;
      }

      //Header cell styles
      th {
        p {
          margin: 4px 0;
        }
      }

      .mat-header-cell {
        font-size: 11px;
      }

      // Table cell styles

      td.mat-cell {
        padding: 0 4px;
        font-size: 12px !important;

        input,
        select {
          min-height: 20px;
        }
      }

      .material-icons {
        font-size: 22px !important;
      }
    }
  }
}
