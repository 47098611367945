/*
 *
 *   lms - Gram Haat
 *
 *
*/

// .mat-form-field-appearance-outline .mat-form-field-infix {
//   padding: 6px 0 1em 0 !important;
// }

::ng-deep .mat-raised-button {
  color: #304156 !important;
  background-color: white !important;
}

@media screen and (max-width: 700px) {
  #page-header,
  #page-title,
  h2 {
    font-size: 12px !important;
  }
}

// // Third pirty style files
// // Sidebar Scroll
@import "~perfect-scrollbar/css/perfect-scrollbar.css";
// // Flag icon
@import "~flag-icon-css/css/flag-icon.min.css";

// // Page loader Topbar
@import "../../vendor/pace/pace-theme-min.css";

// // Highlightjs theme
@import "~highlight.js/styles/github-gist.css";

@import "scss/customisation/mat-expansion-panel";
@import "scss/customisation/mat-style-customisations";

// Main Variables File
@import "scss/variables";

@import "scss/mixins/mixins";
@import "scss/colors";

@import "scss/animations";

// Open _main.scss and keep files which are required for your current layout.
@import "scss/main/main";

// Angular material theme files
@import "scss/themes/init";

@import "scss/utilities/utilities";
@import "scss/components/components";
@import "scss/palette";

// Open _views.scss and remove unnecessary view related files
@import "scss/views/views";

.ps__thumb-y {
  background-color: #2d5660 !important;
}

::-webkit-scrollbar {
  width: 8px !important;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey !important;
  border-radius: 5px !important;
}

::-webkit-scrollbar-thumb {
  background-color: #2d5660 !important;
  border-radius: 5px !important;
}
