#page-header {
  position: sticky;
  top: 0;
  z-index: 999;

  background: #80a7af;
  color: white;
  min-height: 54px;
  justify-content: center;
  padding: 0 12px;
  border-radius: 6px;
  #page-title {
    padding: auto 16px;
    h2 {
      margin: auto 0;
      margin-top: 0;
    }
    h4 {
      margin-top: 0;
    }
  }

  #page-actions {
    margin: 0;
    justify-content: center;
    align-content: center;
    font-weight: 600;
    gap: 8px;
    button {
      &.mat-raised-button {
        width: fit-content !important;
        color: #304156 !important;
      }
      &.mat-button-disabled {
        background-color: lightgrey;
      }
    }
  }
}

#page-content {
  margin-bottom: 12px;
  &.wrapper {
    // padding-top: 12px;
    overflow: auto;
  }
}

* {
  box-sizing: border-box;
}
.lms-form {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  gap: 120px;
  width: 100%;
  padding: 12px 6px 0 6px;
  //   align-items: flex-end;
  &.disabled input {
    background-color: rgb(231, 231, 231);
    pointer-events: none;
    user-select: none;
    border: none;
    ::placeholder {
      display: none;
    }
    :focus {
      outline-style: none;
      box-shadow: none;
      border-color: transparent;
    }
  }

  @media screen and (max-width: 769px) {
    display: flex;
    flex-direction: column;
    gap: 12px;
    .spacer {
      display: none;
    }
    .field-col {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      width: 100%;
      height: 100%;
      flex-grow: 1;
      padding-right: 6px;
    }
    .label-wrapper,
    .field-wrapper {
      width: 90%;
    }
  }
}

.field-col {
  display: flex;
  flex-direction: column;
  justify-content: start;
  width: 100%;
  height: 100%;
  flex-grow: 1;
  padding-right: 24px;
  height: 100%;
}

.lms-field-group-wrapper {
  font-size: 14px !important;
  font-weight: 600 !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-grow: 1;
  padding: 8px 0;

  .label-wrapper,
  input,
  textarea {
    height: 30px;
  }

  mat-slide-toggle {
    min-height: 32px !important;
    padding: 4px 6px;
  }

  input,
  select,
  mat-select,
  p,
  a,
  textarea {
    color: black !important;
    min-height: 32px !important;
    display: flex;
    border: 1px solid #2d5660 !important;
    padding: 4px 6px;
    width: 100% !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    font-family: inherit;
    flex-grow: 1;
    flex-basis: auto;
    margin: 0;
    min-width: 240px;
    border-radius: 4px !important;
    &.date-picker {
      min-width: 120px;
    }
    background-color: white;

    &.inline-field {
      min-width: 7rem;
    }

    .sm {
      width: 30%;
      max-width: 50%;
      min-width: none;
      flex-grow: 0;
    }
    &.compact {
      width: 50%;
      min-width: 120px;
    }
  }

  mat-select {
    min-height: 32px;
    border: 1px solid lightgrey;
    border-radius: 0;
    padding: 4px 6px;
    width: 100%;
    font-size: 14px !important;
    font-weight: 600 !important;
    font-family: inherit;
    margin: 0;
    min-width: 240px;
  }

  input:focus {
    outline: none !important;
    border: 1px solid #80a7af;
  }

  input:disabled,
  textarea:disabled {
    color: gray !important;
    font-weight: 400;
  }
  .field-wrapper {
    display: flex;
    flex-grow: 1;
    &.compact {
      input {
        flex-grow: 0;
        flex-shrink: 1;
      }
      flex-grow: 0;
    }
    &.compact-date {
      input,
      p {
        width: 110px;
        min-width: auto;
        flex-grow: 0;
        flex-shrink: 1;
      }
      flex-grow: 0;
    }

    p.long {
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
      // overflow: hidden;
      max-height: 32px;
      min-width: 240px;
      max-width: 320px;
      &:hover {
        overflow: visible;
        border: none;
        color: #646464;
      }
    }
  }

  .label-wrapper {
    display: flex;
    font-weight: 600;
    font-family: inherit;
    align-items: center;
    width: 40%;
    flex-shrink: 2;
    &.inline-field {
      width: 20%;
      margin-right: 6px;
    }
  }

  @media screen and (max-width: 1440px) {
    flex-direction: column;
    align-items: flex-start;
    padding: 3px 0;
    .label-wrapper,
    .field-wrapper {
      width: 100%;
    }
    .field-col {
      padding-right: 12px;
    }
    .spacer {
      display: none;
    }
    input,
    select,
    p,
    textarea {
      width: 100%;
      min-width: none;
    }
  }
}

input.ng-invalid.ng-touched {
  border: 1px solid red !important;
}

//Global input style for focus
input:focus {
  outline: none !important;
  border: 1px solid #2d5660;
}

p.number {
  justify-content: right;
}

/* Following scss will be used on Master View Page - for Info Table */
.infotable {
  border: 1px solid #ccc;
  .header {
    font-weight: bolder;
    border-bottom: 1px solid #ccc;
  }
  .rows {
    padding: 10px;
    text-align: left;
    display: flex; /* aligns all child elements (flex items) in a row */
  }
  .col {
    align-items: center;
    flex: 1; /* distributes space on the line equally among items */
  }
}

lms-page-header {
  button {
    background: none !important;
    background-color: #ffffff !important;
    color: #2d5660 !important;
    width: 80px !important;
    min-width: 0 !important;
    margin: 0 !important;
  }
}

//Content wrapper ensures the child elements have sufficient vertical padding
.content-wrapper {
  padding: 16px 0;
}

/* Hide arrows in number fields*/
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
  text-align: right;
}

/* Firefox */
input[type="number"] {
  text-align: left;
}

input.long {
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  // overflow: hidden;
  max-height: 32px;
  min-width: 240px;
  max-width: 320px;
  &:hover {
    overflow: visible;
    border: none;
    color: #646464;
  }
}

// -----------------------------------------------------------------------------------------------------
// Table Content Style
// -----------------------------------------------------------------------------------------------------

/* Table Content */
.tablecontent {
  /* Toolbar */
  .toolbar {
    min-height: 40px;
  }

  /* Table Row Action */
  .table-row-actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 0;
    .action,
    .warning {
      display: flex;
      flex-direction: row;
      align-items: center;
      min-height: 18px;
      min-width: 18px;
      margin: 0 4px;

      mat-icon {
        width: 18px;
        height: 18px;
        font-size: 18px;
        opacity: 0.6;
        padding: 0;
        margin: 0;
        color: #2d5660;
        &.error {
          color: red;
        }
        &.delete {
          color: #2d5660;
          width: 24px;
          height: 24px;
          opacity: 1;
          font-size: 24px;
          cursor: pointer;
        }
      }
    }
    /* Warning in tabel content */
    .warning {
      margin-left: -4px;
    }
  }
}

/*** Table Content Style END ***/

// -----------------------------------------------------------------------------------------------------
// Bulk Upload Style & Select and Open input
// -----------------------------------------------------------------------------------------------------

/* Toolbar And Button Style */
.bulktoolbar {
  margin-bottom: 6px;
  button {
    min-width: unset !important;
    padding: unset;
    // width: 64px;
    padding: 0 16px;
    color: white !important;
    height: 36px;
    mat-icon {
      margin: 0 4px;
    }
  }
}

/* Bulk Import Panel */
#bulk-import-panel {
  margin: 0;
  .label-wrapper {
    display: flex;
    width: 100%;
    margin-right: 12px;
    font-size: 14px !important;
    font-weight: 600 !important;
    font-family: inherit;
    flex-grow: 1;
    flex-basis: auto;
  }
  .field-wrapper {
    input,
    p {
      display: flex;
      border: 1px solid lightgrey;
      border-radius: 0;
      padding: 4px 6px;
      width: 100%;
      font-size: 14px !important;
      font-weight: 600 !important;
      font-family: inherit;
      flex-grow: 1;
      flex-basis: auto;
      min-width: 360px;
      height: 36px;
      margin: 0 4px;
    }
  }

  button.upload {
    margin-left: 6px;
  }
}

/* file name in Bulk Upload  */
p#filename {
  margin: 0 6px;
}

/* File input in Bulk Upload  */
.file-input {
  display: none !important;
}

.file {
  display: none !important;
}

/** BulkUpload style END **/

@media screen and (max-width: 1230px) {
  // .wrapper {
  //   overflow-x: scroll !important;
  // }

  .lms-field-group-wrapper input,
  .lms-field-group-wrapper select,
  .lms-field-group-wrapper p,
  .lms-field-group-wrapper textarea {
    // padding: 0 4px !important;
    font-size: 12px !important;
  }

  .lms-field-group-wrapper .label-wrapper,
  .lms-field-group-wrapper input,
  .lms-field-group-wrapper textarea {
    height: 30px !important;
  }

  #page-header {
    padding: 0px 10px 1px;
    min-height: 7.35vh;
    .mat-raised-button {
      line-height: 30px;
      padding: 0 16px;
      border-radius: 3px;
    }
  }
  .form-field {
    display: grid !important;
  }

  .field-col {
    padding-right: 0px !important;
  }
}

@media screen and (max-width: 700px) {
  // .wrapper {
  //   overflow-x: scroll !important;
  // }

  .lms-field-group-wrapper input,
  .lms-field-group-wrapper select,
  .lms-field-group-wrapper p,
  .lms-field-group-wrapper textarea {
    // padding: 0 4px !important;
    font-size: 12px !important;
  }

  .lms-field-group-wrapper .label-wrapper,
  .lms-field-group-wrapper input,
  .lms-field-group-wrapper textarea {
    height: 30px !important;
  }

  #page-header {
    padding: 0px 10px 1px;
    min-height: 7.35vh;
    .mat-raised-button {
      line-height: 32px;
      padding: 0 8px;
      border-radius: 3px;
    }
  }
}

.mat-checkbox-frame {
  border-radius: 20% !important;
}
.mat-checkbox-inner-container {
  width: 1.5rem !important;
  height: 1.5rem !important;
}
.mat-checkbox-background {
  border-radius: 20% !important;
}

.search {
  min-height: 30px;
  height: 33px;
  min-width: 40px !important;
  width: 40px !important;
  padding: 0 0 !important;

  color: white !important;
  margin-left: 0.3rem !important;
  border-radius: 4px !important;
  background-color: #80a7af !important;
  .mat-icon {
    height: 27px !important;
  }
}

.inputgroupbox {
  position: relative;
}
.inputgroupicon {
  position: absolute;
  right: 0;
  button {
    background: transparent;
    color: rgb(45 86 96) !important;
    border: none;
    box-shadow: none;
    padding-top: 4px;
    cursor: pointer;
  }
}

.popup-paginator {
  position: relative;
  bottom: 0;
}
.prod-box {
  display: flex !important;
  flex-wrap: wrap;
}

/* Ends */
