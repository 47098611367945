mat-sidenav-content {
  overflow: hidden !important;
}

/* main-content-wrap will be content area .. max-height will set some area in bottom for footer */
#main-content-wrap {
  // margin-top: 48px;
  margin-bottom: 48px;
  overflow: hidden;
  // max-height: calc(100% - 60px);
}

@media screen and (max-width: 959px) {
  app-footer {
    // left: 0;
    transition: 0.3s all ease-in;
  }
}

.content-area {
  padding-top: 16px;
}

.mat-snack-bar-container {
  background-color: darkgray;
}

app-header-side {
  .mat-toolbar {
    z-index: 999 !important;
  }
}

snack-bar-container {
  background-color: #ea8b2b !important;
  color: white !important;
  margin: 8px !important;

  simple-snack-bar {
    button {
      background-color: white !important;
      color: #2d5660 !important;
      font-size: 12px !important;
      height: 24px !important;
      line-height: normal !important;
      padding: 0 6px !important;
    }

    .mat-button-wrapper {
      color: #034568 !important;
    }
  }
}

mat-tab-body {
  padding: 16px 12px !important;
}

/* customise button is used on login page - this is gradient button */
.customisebutton {
  align-items: flex-end;
  background: linear-gradient(93deg, #703e97 0%, #9a0e81 100%) 0% 0% no-repeat
    padding-box;
  color: #fafafa !important;
  margin-right: 10px;
  margin-bottom: 10px;
  margin: 10px;
}

/* when customise button is disabled - following css will be applied */
.customisebutton.mat-button-disabled {
  background: linear-gradient(60deg, #703e97 0%, #854f8a 50%, #795372 100%);
  color: #caccec !important;
}

.req {
  color: red;
}

.mat-sort-header-content {
  display: block !important;
  flex-grow: 0.9;
}

.mat-sort-header-arrow {
  color: white !important;
}

th,
td {
  border-right: 1px solid lightgray;
}

.mat-datepicker-toggle {
  .mat-icon-button {
    height: 32px;
    width: 32px;
    line-height: 32px;
    padding-top: 0px !important;
  }
}

.mat-paginator-container {
  justify-content: flex-start !important;
}

.notificationPanelClass {
  width: 15%;
}

@media screen and (max-width: 990px) {
  .notificationPanelClass {
    width: 50%;
  }
}
