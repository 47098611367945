.mat-row:nth-child(odd) {
  background: #eef3f0 ;
}
:host ::ng-deep.mat-paginator .mat-paginator-container {
  font-size: medium;
  font-weight: 600;
  color: black;
}
:host ::ng-deep.mat-cell,
td.mat-footer-cell {
  padding: 0px 10px 0px;
  border-bottom-color: rgba(0, 0, 0, 0.199);
}
.table {
  width: 100%;
}

td.mat-cell button {
  background: transparent;
  border: 0px none;
  color: #2d5660;
  a {
    color: #2d5660;
  }
}



.inlinesearch {
  min-height: 30px;
  display: flex;
  border: 1px solid lightgrey;
  padding: 4px;
  width: 100%;
  font-size: 14px !important;
  font-weight: 600 !important;
  font-family: inherit;
  flex-grow: 1;
  flex-basis: auto;
  margin: 0 0 8px 0;
  border-radius: 5px;
}

.inlinesearch:focus {
  outline: none !important;
  border: 1px solid #2d5660;
  ;
}

.action {
  color: #2d5660 !important;
}

.deletecheckbox {
  margin-top: 7px;
  width: 40px;
  height: 15px;
}

td.number {
  text-align: right;
}

th.mat-header-cell {
  text-align: center !important;
}

@media screen and (max-width: 1440px) {
  .inlinesearch {
    font-size: 12px !important;
    padding: 0 4px !important;
    margin: 0 0 4px 0;
  }
}
