mat-expansion-panel {
  // margin: 1rem 0;
  box-shadow: none !important;
  background: none !important;
}

.mat-expansion-panel-header,
.mat-expansion-panel-header:hover {
  background: #80a7af   !important;
}

.mat-expansion-panel-header-description {
  justify-content: space-between;
  align-items: center;
  flex-basis: 0;
}

.mat-expansion-panel-header-title {
  color: white !important;
}

mat-panel-title {
  color: #702082;
}

.mat-expansion-panel-body {
  padding: 0 !important;
}

.icon {
  color: white;
  font-size: 1.5rem;
}

.mat-expansion-panel-header {
  padding: 0 12px !important;
}

.mat-expansion-panel-header-description {
  margin-right: 0 !important;
}

.mat-expansion-panel-header.mat-expanded {
  height: 48px !important;
}

.mat-expansion-panel {
  margin: 10px 0 !important;
}

@media screen and (max-width: 1230px) {
  .mat-expansion-panel-header.mat-expanded {
    height: 38px;
  }

  .mat-expansion-panel-header {
    height: 38px;
  }
}